<template>
  <div class="activity">
    <div class="list-activity">
      <b-container size="sm" class="list-activity-container">
        <b-skeleton-wrapper :loading="loading">
          <div class="contentCustom">
            <div v-if="active" class="back">
              <p @click="handleBack">Kembali</p>
            </div>

            <div class="body">
              <!-- Saldo Efektif -->
              <div v-if="!active" id="heading" class="jarak">
                <p class="heading">Saldo Efektif</p>
                <div class="saldo">
                  <!-- <p class="format">Rp</p> -->
                  <p v-if="!loading" class="money">
                    {{ balance | formatCurrency }}
                  </p>
                  <p v-else class="money">- -</p>
                </div>
                <!-- <b-button :disabled="loading" @click="$router.push('withdraw/history')">History</b-button> -->
              </div>

              <!-- Informasi Rekening -->
              <div v-if="!active" class="contantInformation jarak">
                <div v-if="error" class="item">
                  <h1>{{ error }}</h1>
                </div>
                <div v-else-if="loading" class="item">
                  <div class="d-flex justify-content-center">
                    <b-spinner style="width: 50px; height: 50px" size="lg"></b-spinner>
                  </div>
                </div>
                <div v-else class="item">
                  <h1>Informasi Rekening:</h1>
                  <div class="content">
                    <div class="information">
                      <p>Bank Penerbit</p>
                      <p>{{ selectedBank?.banks?.bank_name || "-" }}</p>
                    </div>
                    <div class="divider"></div>
                    <div class="information">
                      <p>Nama Pemilik Akun</p>
                      <p>{{ selectedBank?.account_name || "-" }}</p>
                    </div>
                    <div class="divider"></div>
                    <div class="information">
                      <p>Nomor Rekening Pribadi</p>
                      <p>{{ selectedBank?.account_number || "-" }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Transfer ke Rekening -->
              <div v-if="active" class="contantInformation jarak">
                <div class="item">
                  <h1>Transfer Ke Rekening:</h1>
                  <div class="content">
                    <div class="information">
                      <p>{{ selectedBank?.banks?.bank_name || "-" }}</p>
                      <p>{{ selectedBank?.account_number || "-" }}</p>
                    </div>
                    <div class="divider"></div>
                    <div class="information">
                      <p>Nama Pemilik Akun</p>
                      <p>{{ selectedBank?.account_name || "-" }}</p>
                    </div>

                    <p class="inform">
                      Permintaan penarikan dana di atas pukul 10.00 WIB akan
                      diproses di hari kerja bursa berikutnya
                    </p>
                  </div>
                </div>
              </div>

              <!-- Input Penarikan Dana -->
              <div class="input jarak" role="group" v-if="active">
                <b-col cols="10" role="group">
                  <b-input id="nominalInputForm" :formatter="formatter"
                    :state="formStateNominal === null ? null : false" type="text" v-model="nominal"
                    class="mr-2"></b-input>
                  <b-form-invalid-feedback id="hargaPembelianInput">{{
                    formStateTextNominal
                  }}</b-form-invalid-feedback>
                </b-col>
                <b-form-invalid-feedback id="nominalInputForm">{{
                  formStateTextNominal
                }}</b-form-invalid-feedback>
              </div>

              <template v-if="active">
                <div class="disclaimer">
                  <h6>Disclaimer</h6>
                  <ol v-if="!expanded">
                    <li>Jumlah transfer maksimum ke Dana adalah 10 juta.</li>
                  </ol>
                  <ol v-if="expanded">
                    <li>Jumlah transfer maksimum ke Dana adalah 10 juta.</li>
                    <li>Akan ada biaya transfer yang akan mengurangi jumlah dana yang masuk ke aplikasi DANA.</li>
                    <li>
                      Apabila setelah penarikan dana berhasil diproses dan terdapat saldo di DANA sebesar Rp 20 juta,
                      maka ada potensi direject oleh sistem dari DANA. Dalam hal ini, nasabah harus memahami dan
                      menyadari
                      ketersediaan saldo di aplikasi DANA.
                    </li>
                    <li>
                      Maksimal transaksi melalui DANA dalam satu bulan adalah 40 juta. Jadi, jika nasabah melakukan
                      transaksi lebih dari 40 juta dalam satu bulan, maka akan ditolak.
                    </li>
                  </ol>
                  <button @click="toggleExpand" class="read-more-btn">
                    {{ expanded ? 'Baca sedikit' : 'Baca lebih' }}
                  </button>
                </div>
              </template>

              <!-- Tombol Tarik Dana -->
              <b-button v-if="!active" :disabled="loading || !selectedBank" @click="active = 'confirm'" block
                class="buttonTarik jarak">
                Tarik Dana
              </b-button>
              <b-button v-else :disabled="loading || (active && formStateNominal !== null)" @click="tarikDana()" block
                class="buttonTarik jarak">
                {{ loading ? "..." : "Tarik Dana" }}
              </b-button>
            </div>
          </div>
        </b-skeleton-wrapper>
      </b-container>
    </div>

    <Footer3 />

    <!-- Modal Pesan -->
    <ModalMessage :open="!!openModalMessage" :closeHandler="closeModalMessage" :title="openModalTitle"
      :bodyText="openModalMessage || ''" :buttons="modalButton" />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ModalMessage from "@/components/partials/modal/message.vue";
import { formatter, backFormatter } from "@/utils";
import instance from "@/utils/api";

export default {
  data() {
    return {
      account: {},
      active: false, // Pastikan semua data didefinisikan dengan benar
      loading: false,
      balance: 0,
      access_token: this.$route.query.auth_token,
      error: null,
      nominal: "",
      formStateNominal: null,
      formStateTextNominal: "",
      modalButton: [],
      s21ClientData: {},
      clientId: null,
      openModalMessage: false,
      openModalTitle: "",
      expanded: false
    };
  },
  components: {
    ModalMessage,
  },
  head() {
    return {
      title: "Idle-Money-Decline",
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
    }),
    formStateBackNominal() {
      return this.backFormatter(this.nominal);
    },
    selectedBank() {
      // Jika tidak ada akun, maka kembalikan null
      // if (
      //   !Array.isArray(this.user?.individual?.accounts) ||
      //   !this.s21ClientData ||
      //   !this.s21ClientData?.PrivateBankAccountNo
      // ) {
      //   return null;
      // }
      // const found = this.user.individual.accounts.find((candidate) => {
      //   return (
      //     candidate.account_number == this.s21ClientData?.PrivateBankAccountNo
      //   );
      //   // // Select Bank Default
      //   // return candidate.default
      // });
      // if (found) {
      //   return found;
      // }
      return this.account;
      // return this.user.individual.accounts[0]
    },
  },
  async mounted() {
    try {
      const access_token = this.$route.query.auth_token;
      this.access_token = access_token;

      // Pertama, tunggu respons dari s21client
      const check = await instance({
        method: "GET",
        url: `v4/withdrawal/check?token=${this.$route.query.token}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (check.data.meta && check.data.meta.message === 'Token has expired') {
        alert('Token telah kedaluwarsa. Silakan periksa kembali.');
        return; // Menghentikan eksekusi program
      }

      const s21client = await instance.get(`s21client?clientId=${check.data.data.token}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      this.s21ClientData = s21client?.data?.data;
      this.clientId = s21client?.data?.data?.ClientID;

      // Jalankan kedua API secara paralel menggunakan Promise.all
      const [balanceResponse, accountResponse] = await Promise.all([
        // Request balance
        instance({
          method: "GET",
          url: `${this.app_url}v4/balance/position?type=2&client_id=${this.clientId}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }),

        // Request account
        instance({
          method: "GET",
          url: `${this.app_url}v4/individual-account/detail-account?client_id=${this.clientId}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }),
      ]);

      // Assign data setelah kedua request selesai
      this.balance = balanceResponse.data.data.amount;
      this.account = accountResponse.data.data;
      return balanceResponse;

    } catch (error) {
      // Menangani error di semua request, termasuk kemungkinan token expired
      if (error.response && error.response.data.meta && error.response.data.meta.message === 'Token has expired') {
        alert('Token telah kedaluwarsa. Silakan periksa kembali.');
        return; // Menghentikan eksekusi program
      } else {
        console.error('Error terjadi:', error);
      }
    }
  },

  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    handleBack() {
      this.active = false;
    },
    async tarikDana() {
      try {
        if (!this.loading) {
          this.loading = true;

          await instance({
            method: "POST",
            url: `${this.app_url}v4/fbonds/cash-withdrawals`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.access_token}`,
            },
            data: {
              date: moment().format("YYYY-MM-DD"),
              client_id: this.clientId,
              amount: `${this.formStateBackNominal}`,
              description: "Cashout",
              bank_account_no: this.selectedBank.account_number,
            },
          });

          this.openModalTitle = "Berhasil";
          this.openModalMessage = "Berhasil melakukan withdraw";
        }
      } catch (e) {
        console.log(e);
        this.openModalTitle = "Terjadi Kesalahan";
        this.openModalMessage = "Terjadi kesalahan saat melakukan withdraw";
      } finally {
        this.loading = false;
      }
    },
    backTransaformFormatter(val) {
      if (!val) return;
      return val
        .toString()
        .split("")
        .map((val) => val.replace(".", ","))
        .join("");
    },
    closeModalMessage() {
      this.openModalMessage = false;
    },
    formatter,
    backFormatter,
  },
  filters: {
    formatCurrency(value) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.contentCustom {
  width: 500px;
  margin: 0 auto;

  .back {
    p {
      color: #6b7280;
      /* Text Desktop Base/Bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.5;
      }
    }
  }

  .body {
    padding: 35px;
    background-color: white;

    #heading {
      display: grid;
      justify-items: center;
      gap: 20px;

      .saldo {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        overflow: auto;

        .format {
          color: #9ca3af;
          font-family: Inter;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          margin: 0;
        }

        .money {
          color: #111827;
          font-family: Inter;
          font-size: 45px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          margin: 0;
        }
      }

      .heading {
        color: var(--gray-900, #111827);
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      button {
        width: 100%;
        background-color: #b4c9ff;
        padding: 12px 16px;
        border-radius: 6px;
        color: #0b318f;
        text-align: center;
        /* Text L/Bold */
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        /* 24px */
      }
    }

    .heading {
      color: #111827;
      text-align: center;
      /* Text Desktop LG/Bold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .contantInformation {
      display: grid;
      gap: 44px;

      .input {
        p.inform {
          color: #6b7280;
          /* Text M/Regular */
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .item {
        display: grid;
        gap: 10px;

        h1 {
          color: #1f2937;
          /* Text L/Bold */
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }

        .content {
          display: grid;
          gap: 8px;

          .information {
            display: flex;
            justify-content: space-between;

            p:first-child {
              color: #374151;
              /* Text Desktop Base/Regular */
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }

            p:last-child {
              color: #111827;
              text-align: right;
              /* Text Desktop Base/Bold */
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
            }
          }

          .inform {
            color: #374151;
            /* Text Desktop Base/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }

          .divider {
            width: 100%;
            height: 1px;
            background-color: #e5e7eb;
          }
        }
      }
    }

    .buttonTarik {
      background: #ff6975;
      padding: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      transition: 0.3s;
    }

    .buttonTarik:active {
      opacity: 0.5;
    }

    .buttonTarik:hover {
      opacity: 0.8;
    }

    .buttonTarik:focus {
      opacity: 0.6;
    }

    .jarak {
      margin-top: 59px;
    }
  }
}

.activity {
  background: #f3f4f6;
}

.investment-activity {
  background: white;
}

.activity-container {
  padding-top: 120px;
  margin-bottom: 0px;
}

.list-activity-card {
  box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
    0px 1px 3px rgba(107, 114, 128, 0.15);
  border-radius: 6px;
}

.list-activity {
  background: #f3f4f6;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.activity-title {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    color: #111827;
  }
}

.transaction-date {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-type {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-promo {
  font-weight: 700;
  font-size: 12px;
  color: #2c5282;
  background: #ebf8ff;
  border-radius: 6px;
}

.transaction-status {
  font-weight: 700;
  font-size: 12px;
  color: #ce2512;
  background: #fdeeec;
  border-radius: 20px;
}

.fund-padding {
  margin-left: 20px;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
    color: #1f2937;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: #6b7280;
  }
}

.investment-value {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.purchased {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
  }
}

.estimation {
  p {
    font-weight: 400;
    font-size: 14px;
    color: #6b7280 !important;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    color: #111827;
  }
}

.on-proccess {
  border-bottom: 3px solid $tertiaryColor;

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.finish {
  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.timer-buy {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
  }
}

.switch-from {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.switch-to {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.btn-buy {
  font-family: $inter;
  background: $secondaryColor;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border: none;
  color: white;
}

.btn-buy:hover {
  background: $hoverSecondary;
}

.btn-buy:focus {
  box-shadow: none !important;
}

.btn-buy:active {
  background: $hoverSecondary !important;
  outline: none !important;
}

.btn-modal-portfolio {
  font-family: $inter;
  background: transparent;
  padding: 0;
  border: none;
}

.btn-modal-portfolio:hover {
  background: transparent !important;
}

.btn-modal-portfolio:focus {
  box-shadow: none !important;
  background: transparent !important;
}

.btn-modal-portfolio:active {
  background: transparent !important;
  outline: none !important;
}


.disclaimer {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: -20px;
}

h6 {
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

ol {
  padding-left: 20px;
  color: #6b7280;
}

.read-more-btn {
  border: none;
  border-radius: 4px;
  color: #007bff;
  font-size: 0.9em;
  cursor: pointer;
  transition: color 0.3s ease;
  background: transparent;
}

.read-more-btn:hover {
  color: #0056b3;
}

@media only screen and (max-width: 768px) {
  h4 {
    font-size: 14px !important;
  }

  h3 {
    font-size: 14px !important;
  }

  p {
    font-size: 14px !important;
  }
}
</style>
